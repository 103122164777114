import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import axios from 'axios'
import config from '@/config'

export const ErrorMsg = {
  ResponseError: (status: number) => 'API response error status:' + status,
  InvitedCheckedGroupNotFound: 'グループが見つかりませんでした',
  GroupViewingApplicationError: 'グループ閲覧許可の申請に失敗しました',
  FailedToGroupDeleteError: 'グループの解除に失敗しました',
  FetchOrganizationGroupFailureError: '組織グループ取得に失敗しました',
} as const

export const Status = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  DELETED: 'deleted',
  EXPIRED: 'expired',
  UNREGISTERED: 'unregistered',
} as const
export type StatusType = typeof Status[keyof typeof Status]

export type InvitedCheckedGroup = { name: string }
export type OrganizationGroup = {
  id: string
  groupId: string
  name: string
  invitationId: string
  invitedDate: string
  status: StatusType
  order: number
}

@Module({ dynamic: true, namespaced: true, name: 'groupSettings', store })
class Mod extends VuexModule {
  invitedCheckedGroup: InvitedCheckedGroup = { name: '' }
  organizationGroups: OrganizationGroup[] = []

  @Mutation
  setInvitedCheckedGroup(group: InvitedCheckedGroup): void {
    this.invitedCheckedGroup = group
  }

  @Mutation
  setOrganizationGroups(orgGroups: OrganizationGroup[]): void {
    this.organizationGroups = orgGroups
  }

  @Action({ commit: 'setInvitedCheckedGroup', rawError: true })
  async checkInvitationId({ invitationId }: { invitationId: string }): Promise<InvitedCheckedGroup> {
    const url = `${config.enterprise.apiUrl}/groups/check-invitation-id`
    try {
      const res = await axios.post(url, { invitationId })
      const name = res.data.name as string
      return { name }
    } catch (e) {
      throw new Error(ErrorMsg.InvitedCheckedGroupNotFound)
    }
  }

  @Action({ rawError: true })
  async applyViewPermission({ invitationId }: { invitationId: string }): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-groups/apply-view-permission`
    try {
      await axios.post(url, { invitationId })
    } catch (e) {
      throw new Error(ErrorMsg.GroupViewingApplicationError)
    }
  }

  @Action({ rawError: true })
  async delete(organizationGroupId: string): Promise<void> {
    const url = `${config.enterprise.apiUrl}/no-group-check/organization-groups/delete`
    try {
      await axios.post(url, { organizationGroupId: organizationGroupId })
    } catch (e) {
      throw new Error(ErrorMsg.FailedToGroupDeleteError)
    }
  }

  // TODO: ./OrganizationGroupsに一本化したい
  @Action({ commit: 'setOrganizationGroups', rawError: true })
  async fetchOrganizationGroups(): Promise<OrganizationGroup[]> {
    const url = `${config.enterprise.apiUrl}/organization-groups`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchOrganizationGroupFailureError)
    }
  }
}

export default getModule(Mod)
