import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import axios from 'axios'
import config from '@/config'

export const ErrorMsg = {
  ResponseError: (status: number) => 'API response error status:' + status,
  FailedToRemind: 'リマインド発信に失敗しました',
} as const

export type RemindUser = {
  isTarget: boolean
  isReminded: boolean
  groupName: string
  userId: string
  userName: string
  latestRegistrationDateTime: string
  latestBodyTemp: number
  latestMessage: string
}

@Module({ dynamic: true, namespaced: true, name: 'users', store })
class Mod extends VuexModule {
  allUsersCount = 0
  remindUsers: RemindUser[] = []

  @Mutation
  setAllUsersCount(allUsersCount: number): void {
    this.allUsersCount = allUsersCount
  }

  @Mutation
  setRemindUsers(remindUsers: RemindUser[]): void {
    this.remindUsers = remindUsers
  }

  @Action({})
  async authenticateUser(data: { email: string; password: string }): Promise<string> {
    const url = `${config.enterprise.apiUrl}/users/authentication`
    const res = await axios.post(url, { email: data.email, password: data.password })

    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data
  }

  @Action({ commit: 'setAllUsersCount' })
  async fetchAllUsersCount(): Promise<number> {
    const url = `${config.enterprise.apiUrl}/users/count`
    const res = await axios.get(url)

    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data
  }

  @Action({ commit: 'setRemindUsers' })
  async fetchRemindUsers(): Promise<RemindUser[]> {
    const url = `${config.enterprise.apiUrl}/users/no-condition`
    const res = await axios.get(url)

    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data.remindUsers
  }

  @Action({ rawError: true })
  async remind({ userIds }: { userIds: string[] }): Promise<void> {
    const url = `${config.enterprise.apiUrl}/users/remind`
    try {
      await axios.post(url, { userIds })
    } catch (e) {
      throw new Error(ErrorMsg.FailedToRemind)
    }
  }
}

export default getModule(Mod)
