









import Spinner from '@/components/atoms/Spinner.vue'
import RemindUserTable from '@/components/organisms/Remind/RemindUserTable.vue'
import menuStore from '@/store/Menu'
import userStore from '@/store/Users'
import organizationStore from '@/store/Organizations'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { Spinner, RemindUserTable } })
export default class extends Vue {
  isLoading = false

  async created(): Promise<void> {
    this.isLoading = true
    try {
      await Promise.all([organizationStore.checkRemindStatus(), userStore.fetchRemindUsers()])
    } finally {
      this.isLoading = false
    }
  }

  mounted(): void {
    menuStore.setActiveFlg({ remind: true })
  }
}
