



























import Spinner from '@/components/atoms/Spinner.vue'
import Modal from '@/components/molecules/Modal.vue'
import userStore, { RemindUser } from '@/store/Users'
import organizationStore from '@/store/Organizations'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: { Modal, Spinner } })
export default class extends Vue {
  @Prop({ type: Array, required: true }) remindUsers!: RemindUser[]

  isSuccess = false
  isLoading = false
  errorMessage = ''

  get isRemindable(): boolean {
    return organizationStore.isRemindable
  }

  get targetExists(): boolean {
    return this.remindUsers.find((user) => user.isTarget) !== undefined
  }

  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
    this.isSuccess = false
  }

  async remind(): Promise<void> {
    this.isLoading = true
    try {
      const userIds = this.remindUsers.filter((user) => user.isTarget).map((user) => user.userId)
      await userStore.remind({ userIds })
      await Promise.all([organizationStore.checkRemindStatus(), userStore.fetchRemindUsers()])
      this.isSuccess = true
    } catch (e) {
      const error = e as Error
      this.errorMessage = error.message
    } finally {
      this.isLoading = false
    }
  }
}
