




import { Status } from '@/store/GroupSettings'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: String }) groupStatus?: string
  get status(): string {
    switch (this.groupStatus) {
      case Status.APPROVED:
        return '登録済'
      case Status.WAITING:
        return '申請中'
      case Status.REJECTED:
        return '拒否'
      case Status.EXPIRED:
        return '期限切れ'
      case Status.DELETED:
        return '削除済み'
      default:
        return '未登録'
    }
  }
}
