





















































import Status from '@/components/atoms/Group/Status.vue'
import RemindModal from '@/components/organisms/Remind/RemindModal.vue'
import userStore, { RemindUser } from '@/store/Users'
import dayjs from '@/libs/dayjs'
import VABox from 'va/widgets/VABox.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({ components: { VABox, RemindModal, Status } })
export default class extends Vue {
  remindUsers: RemindUser[] = []

  get getRemindUsers(): RemindUser[] {
    return userStore.remindUsers
  }

  @Watch('getRemindUsers', { immediate: true })
  setRemindUsers(): void {
    this.remindUsers = this.getRemindUsers
  }

  formatDate(date: string): string {
    if (date === null) return '-'
    return dayjs(date).format('YYYY/MM/DD HH:mm')
  }

  get isAllChecked(): boolean {
    return this.remindUsers.every((user) => user.isTarget)
  }
  set isAllChecked(value: boolean) {
    for (let i = 0; i < this.remindUsers.length; i++) {
      this.remindUsers.splice(i, 1, {
        ...this.remindUsers[i],
        isTarget: value,
      })
    }
  }
}
